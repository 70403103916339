import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: {
            schema: Yup.object().shape({
                serial_no: Yup.number(),
                brand: Yup.string(),
                model: Yup.string(),
                company_id: Yup.number()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                purchased_at: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                erase_device_memory: Yup.boolean(),
                ble_connection_period: Yup.number()
                    .min(1, 'Deve ser maior ou igual a 1')
                    .max(254, 'Deve ser menor ou igual a 254')
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                autosync: Yup.boolean(),
                shared_users: Yup.array().of(
                    Yup.object().shape({
                        user_id: Yup.number()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .required('Campo obrigatório'),
                    })
                ),
            }),
        },
        manager: {
            schema: Yup.object().shape({
                serial_no: Yup.number(),
                brand: Yup.string(),
                model: Yup.string(),
                company_id: Yup.number()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                purchased_at: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                erase_device_memory: Yup.boolean(),
                ble_connection_period: Yup.number()
                    .min(1, 'Deve ser maior ou igual a 1')
                    .max(254, 'Deve ser menor ou igual a 254')
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                autosync: Yup.boolean(),
                shared_users: Yup.array().of(
                    Yup.object().shape({
                        user_id: Yup.number()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .required('Campo obrigatório'),
                    })
                ),
            }),
        },
        condor_admin: {
            schema: Yup.object().shape({
                serial_no: Yup.number(),
                brand: Yup.string(),
                model: Yup.string(),
                company_id: Yup.number()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                erase_device_memory: Yup.boolean(),
                ble_connection_period: Yup.number()
                    .min(1, 'Deve ser maior ou igual a 1')
                    .max(254, 'Deve ser menor ou igual a 254')
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                autosync: Yup.boolean(),
                purchased_at: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
            }),
        },
    },
    en: {
        customer_admin: {
            schema: Yup.object().shape({
                serial_no: Yup.number(),
                brand: Yup.string(),
                model: Yup.string(),
                company_id: Yup.number()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                purchased_at: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                erase_device_memory: Yup.boolean(),
                ble_connection_period: Yup.number()
                    .min(1, 'Must be greater than or equal 1')
                    .max(254, 'Must be lower than or equal 254')
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                autosync: Yup.boolean(),
                shared_users: Yup.array().of(
                    Yup.object().shape({
                        user_id: Yup.number()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .required('Required field'),
                    })
                ),
            }),
        },
        manager: {
            schema: Yup.object().shape({
                serial_no: Yup.number(),
                brand: Yup.string(),
                model: Yup.string(),
                company_id: Yup.number()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                purchased_at: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                erase_device_memory: Yup.boolean(),
                ble_connection_period: Yup.number()
                    .min(1, 'Must be greater than or equal 1')
                    .max(254, 'Must be lower than or equal 254')
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                autosync: Yup.boolean(),
                shared_users: Yup.array().of(
                    Yup.object().shape({
                        user_id: Yup.number()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .required('Required field'),
                    })
                ),
            }),
        },
        condor_admin: {
            schema: Yup.object().shape({
                serial_no: Yup.number(),
                brand: Yup.string(),
                model: Yup.string(),
                company_id: Yup.number()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                erase_device_memory: Yup.boolean(),
                ble_connection_period: Yup.number()
                    .min(1, 'Must be greater than or equal 1')
                    .max(254, 'Must be lower than or equal 254')
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                autosync: Yup.boolean(),
                purchased_at: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
            }),
        },
    },
};
