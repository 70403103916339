export default {
    pt: {
        customer_admin: {
            activeDevicesText: 'Dispositivos ativos',
            remainingDevicesQuotaText:
                'Dispositivos ativos a serem adicionados',
            searchBySerialNumberText:
                'Procurar dispositivo por número de série',
            addText: 'Adicionar',
            importDevicesText: 'Importar dispositivos',
            batteryDischargeCurveText: 'Curva de descarga de bateria',
            companyText: 'Empresa',
            serialNumberText: 'Número de série',
            modelText: 'Modelo',
            userText: 'Usuário',
            receivedAtText: 'Recebido em',
            lastUpdateText: 'Última atualização',
            sensorsMemoryText: 'Memória dos sensores',
            lastBatteryValueText: 'Último valor de bateria',
            activeText: 'Ativo',
            actionsText: 'Ações',
            activateDeviceText: 'Ativar dispositivo',
            deactivateDeviceText: 'Desativar dispositivo',
            reportText: 'Relatório',
            editText: 'Editar',
            deleteText: 'Excluir',
            successText: 'Dispositivo atualizado com sucesso!',
            warningDeactivateDeviceText:
                'Ao desativar um dispositivo, o dispositivo também é desvinculado do paciente.',
            attentionText: 'Atenção!',
            deactivateText: 'Desativar',
            cancelText: 'Cancelar',
            noDevicesText: 'Você não possui dispositivos.',
        },
        manager: {
            activeDevicesText: 'Dispositivos ativos',
            remainingDevicesQuotaText:
                'Dispositivos ativos a serem adicionados',
            searchBySerialNumberText:
                'Procurar dispositivo por número de série',
            addText: 'Adicionar',
            importDevicesText: 'Importar dispositivos',
            batteryDischargeCurveText: 'Curva de descarga de bateria',
            companyText: 'Empresa',
            serialNumberText: 'Número de série',
            modelText: 'Modelo',
            userText: 'Usuário',
            receivedAtText: 'Recebido em',
            lastUpdateText: 'Última atualização',
            sensorsMemoryText: 'Memória dos sensores',
            lastBatteryValueText: 'Último valor de bateria',
            activeText: 'Ativo',
            actionsText: 'Ações',
            activateDeviceText: 'Ativar dispositivo',
            deactivateDeviceText: 'Desativar dispositivo',
            reportText: 'Relatório',
            editText: 'Editar',
            deleteText: 'Excluir',
            successText: 'Dispositivo atualizado com sucesso!',
            warningDeactivateDeviceText:
                'Ao desativar um dispositivo, o dispositivo também é desvinculado do paciente.',
            attentionText: 'Atenção!',
            deactivateText: 'Desativar',
            cancelText: 'Cancelar',
            noDevicesText: 'Você não possui dispositivos.',
        },
        condor_admin: {
            activeDevicesText: 'Dispositivos ativos',
            remainingDevicesQuotaText:
                'Dispositivos ativos a serem adicionados',
            searchBySerialNumberText:
                'Procurar dispositivo por número de série',
            addText: 'Adicionar',
            importDevicesText: 'Importar dispositivos',
            batteryDischargeCurveText: 'Curva de descarga de bateria',
            companyText: 'Empresa',
            serialNumberText: 'Número de série',
            modelText: 'Modelo',
            userText: 'Usuário',
            receivedAtText: 'Recebido em',
            lastUpdateText: 'Última atualização',
            sensorsMemoryText: 'Memória dos sensores',
            lastBatteryValueText: 'Último valor de bateria',
            activeText: 'Ativo',
            actionsText: 'Ações',
            activateDeviceText: 'Ativar dispositivo',
            deactivateDeviceText: 'Desativar dispositivo',
            reportText: 'Relatório',
            editText: 'Editar',
            deleteText: 'Excluir',
            successText: 'Dispositivo atualizado com sucesso!',
            warningDeactivateDeviceText:
                'Ao desativar um dispositivo, o dispositivo também é desvinculado do paciente.',
            attentionText: 'Atenção!',
            deactivateText: 'Desativar',
            cancelText: 'Cancelar',
            noDevicesText: 'Você não possui dispositivos.',
        },
    },
    en: {
        customer_admin: {
            activeDevicesText: 'Active devices',
            remainingDevicesQuotaText: 'Remaining active devices quota',
            searchBySerialNumberText: 'Search device by serial number',
            addText: 'Add',
            importDevicesText: 'Import devices',
            batteryDischargeCurveText: 'Battery discharge curve',
            companyText: 'Company',
            serialNumberText: 'Serial number',
            modelText: 'Model',
            userText: 'User',
            receivedAtText: 'Received at',
            lastUpdateText: 'Last update',
            sensorsMemoryText: 'Sensors memory',
            lastBatteryValueText: 'Last battery value',
            activeText: 'Active',
            actionsText: 'Actions',
            activateDeviceText: 'Activate device',
            deactivateDeviceText: 'Deactivate device',
            reportText: 'Report',
            editText: 'Edit',
            deleteText: 'Delete',
            successText: 'Device successfully updated!',
            warningDeactivateDeviceText:
                'When deactivating a device, the device is also unbind from the patient.',
            attentionText: 'Attention!',
            deactivateText: 'Deactivate',
            cancelText: 'Cancel',
            noDevicesText: 'You do not have devices.',
        },
        manager: {
            activeDevicesText: 'Active devices',
            remainingDevicesQuotaText: 'Remaining active devices quota',
            searchBySerialNumberText: 'Search device by serial number',
            addText: 'Add',
            importDevicesText: 'Import devices',
            batteryDischargeCurveText: 'Battery discharge curve',
            companyText: 'Company',
            serialNumberText: 'Serial number',
            modelText: 'Model',
            userText: 'User',
            receivedAtText: 'Received at',
            lastUpdateText: 'Last update',
            sensorsMemoryText: 'Sensors memory',
            lastBatteryValueText: 'Last battery value',
            activeText: 'Active',
            actionsText: 'Actions',
            activateDeviceText: 'Activate device',
            deactivateDeviceText: 'Deactivate device',
            reportText: 'Report',
            editText: 'Edit',
            deleteText: 'Delete',
            successText: 'Device successfully updated!',
            warningDeactivateDeviceText:
                'When deactivating a device, the device is also unbind from the patient.',
            attentionText: 'Attention!',
            deactivateText: 'Deactivate',
            cancelText: 'Cancel',
            noDevicesText: 'You do not have devices.',
        },
        condor_admin: {
            activeDevicesText: 'Active devices',
            remainingDevicesQuotaText: 'Remaining active devices quota',
            searchBySerialNumberText: 'Search device by serial number',
            addText: 'Add',
            importDevicesText: 'Import devices',
            batteryDischargeCurveText: 'Battery discharge curve',
            companyText: 'Company',
            serialNumberText: 'Serial number',
            modelText: 'Model',
            userText: 'User',
            receivedAtText: 'Received at',
            lastUpdateText: 'Last update',
            sensorsMemoryText: 'Sensors memory',
            lastBatteryValueText: 'Last battery value',
            activeText: 'Active',
            actionsText: 'Actions',
            activateDeviceText: 'Activate device',
            deactivateDeviceText: 'Deactivate device',
            reportText: 'Report',
            editText: 'Edit',
            deleteText: 'Delete',
            successText: 'Device successfully updated!',
            warningDeactivateDeviceText:
                'When deactivating a device, the device is also unbind from the patient.',
            attentionText: 'Attention!',
            deactivateText: 'Deactivate',
            cancelText: 'Cancel',
            noDevicesText: 'You do not have devices.',
        },
    },
};
