export default {
    pt: {
        customer_admin: {
            serialNumberText: 'Número de série',
            brandText: 'Marca',
            modelText: 'Modelo',
            actLumusText: 'ActLumus',
            companyText: 'Empresa',
            purchaseDateText: 'Data de compra',
            eraseDeviceMemoryText:
                'Apagar memória do dispositivo na próxima conexão?',
            connectionPeriodText: 'Período de conexão com o app (minutos)',
            autosyncText: 'Sincronizar automaticamente ao conectar no app?',
            yesText: 'Sim',
            noText: 'Não',
            successText: 'Dispositivo editado com sucesso!',
            saveText: 'Salvar',
            cancelText: 'Cancelar',
            addText: 'Adicionar',
            removeText: 'Remover',
            mainPatientText: 'Paciente principal',
            patientText: 'Paciente',
        },
        manager: {
            serialNumberText: 'Número de série',
            brandText: 'Marca',
            modelText: 'Modelo',
            actLumusText: 'ActLumus',
            companyText: 'Empresa',
            purchaseDateText: 'Data de compra',
            eraseDeviceMemoryText:
                'Apagar memória do dispositivo na próxima conexão?',
            connectionPeriodText: 'Período de conexão com o app (minutos)',
            autosyncText: 'Sincronizar automaticamente ao conectar no app?',
            yesText: 'Sim',
            noText: 'Não',
            successText: 'Dispositivo editado com sucesso!',
            saveText: 'Salvar',
            cancelText: 'Cancelar',
            addText: 'Adicionar',
            removeText: 'Remover',
            mainPatientText: 'Paciente principal',
            patientText: 'Paciente',
        },
        condor_admin: {
            serialNumberText: 'Número de série',
            brandText: 'Marca',
            modelText: 'Modelo',
            actLumusText: 'ActLumus',
            companyText: 'Empresa',
            purchaseDateText: 'Data de compra',
            eraseDeviceMemoryText:
                'Apagar memória do dispositivo na próxima conexão?',
            connectionPeriodText: 'Período de conexão com o app (minutos)',
            autosyncText: 'Sincronizar automaticamente ao conectar no app?',
            yesText: 'Sim',
            noText: 'Não',
            successText: 'Dispositivo editado com sucesso!',
            saveText: 'Salvar',
            cancelText: 'Cancelar',
        },
    },
    en: {
        customer_admin: {
            serialNumberText: 'Serial number',
            brandText: 'Brand',
            modelText: 'Model',
            actLumusText: 'ActLumus',
            companyText: 'Company',
            purchaseDateText: 'Purchase date',
            eraseDeviceMemoryText: 'Erase device memory in next connection?',
            connectionPeriodText: 'Connection period with app (minutes)',
            autosyncText: 'Automatically sync when connecting to the app?',
            yesText: 'Yes',
            noText: 'No',
            successText: 'Device successfully edited!',
            saveText: 'Save',
            cancelText: 'Cancel',
            addText: 'Add',
            removeText: 'Remove',
            mainPatientText: 'Main patient',
            patientText: 'Patient',
        },
        manager: {
            serialNumberText: 'Serial number',
            brandText: 'Brand',
            modelText: 'Model',
            actLumusText: 'ActLumus',
            companyText: 'Company',
            purchaseDateText: 'Purchase date',
            eraseDeviceMemoryText: 'Erase device memory in next connection?',
            connectionPeriodText: 'Connection period with app (minutes)',
            autosyncText: 'Automatically sync when connecting to the app?',
            yesText: 'Yes',
            noText: 'No',
            successText: 'Device successfully edited!',
            saveText: 'Save',
            cancelText: 'Cancel',
            addText: 'Add',
            removeText: 'Remove',
            mainPatientText: 'Main patient',
            patientText: 'Patient',
        },
        condor_admin: {
            serialNumberText: 'Serial number',
            brandText: 'Brand',
            modelText: 'Model',
            actLumusText: 'ActLumus',
            companyText: 'Company',
            purchaseDateText: 'Purchase date',
            eraseDeviceMemoryText: 'Erase device memory in next connection?',
            connectionPeriodText: 'Connection period with app (minutes)',
            autosyncText: 'Automatically sync when connecting to the app?',
            yesText: 'Yes',
            noText: 'No',
            successText: 'Device successfully edited!',
            saveText: 'Save',
            cancelText: 'Cancel',
        },
    },
};
