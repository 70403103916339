export default {
    pt: {
        customer_admin: {
            downloadTemplateText:
                'Faça o download do arquivo com o formato para adicionar pacientes!',
            requiredFieldsInfoText:
                'Dentro do arquivo há colunas <b>obrigatórias (*)</b> e não obrigatórias a serem preenchidas. As colunas não obrigatórias podem ser deixadas em branco.',
            activeInfoText:
                'Para usuários ativos, digitar <b>1</b>. Para usuários inativos, digitar <b>0</b>.',
            dateFormatInfoText:
                'Para as datas, utilizar o formato <b>aaaa-mm-dd</b>.',
            languageFormatText:
                'Para o idioma do e-mail enviado ao paciente, há três opções: <b>en</b> (Inglês), <b>pt</b> (Português) e <b>ge</b> (Alemão).',
            requiredFieldsWithConditionInfoText:
                'Dentro do arquivo há colunas relacionadas ao dispositivo que pode ser associado a um paciente. Elas são colunas obrigatórias no caso de haver um dispositivo a ser associado a um paciente e são identificadas por <b>(**)</b>.',
            bodyPartText:
                'Para a parte do corpo onde um dispositivo será alocado, há duas opções: <b>wrist</b> (Pulso) e <b>necklace</b> (Colar).',
            feedback1Text: 'Após incluir um arquivo e clicar em',
            feedback2Text: 'Adicionar',
            feedback3Text: ', há dois casos distintos:',
            feedback4Text:
                'Em caso de sucesso, será feito o download de um arquivo csv com uma coluna nova chamada',
            feedback5Text: 'STATUS',
            feedback6Text: 'com valores',
            feedback7Text: 'SUCCESS',
            feedback8Text:
                'Em caso de falha, será feito o download de um arquivo csv com uma coluna nova chamada',
            feedback9Text: 'STATUS',
            feedback10Text: 'com valores',
            feedback11Text: 'FAIL',
            feedback12Text:
                'e novas colunas indicando o erro de cada coluna do arquivo original',
            requiredFieldsText:
                '<b style="color: darkred">*</b> Campos obrigatórios!',
            successText: 'Pacientes adicionados com sucesso!',
            addText: 'Adicionar',
            cancelText: 'Cancelar',
            downloadText: 'Download',
            fileText: 'Arquivo',
            noSelectedFileText: 'Nenhum arquivo selecionado',
            chooseFileText: 'Escolher arquivo...',
        },
        manager: {
            downloadTemplateText:
                'Faça o download do arquivo com o formato para adicionar pacientes!',
            requiredFieldsInfoText:
                'Dentro do arquivo há colunas <b>obrigatórias (*)</b> e não obrigatórias a serem preenchidas. As colunas não obrigatórias podem ser deixadas em branco.',
            activeInfoText:
                'Para usuários ativos, digitar <b>1</b>. Para usuários inativos, digitar <b>0</b>.',
            dateFormatInfoText:
                'Para as datas, utilizar o formato <b>aaaa-mm-dd</b>.',
            languageFormatText:
                'Para o idioma do e-mail enviado ao paciente, há três opções: <b>en</b> (Inglês), <b>pt</b> (Português) e <b>ge</b> (Alemão).',
            requiredFieldsWithConditionInfoText:
                'Dentro do arquivo há colunas relacionadas ao dispositivo que pode ser associado a um paciente. Elas são colunas obrigatórias no caso de haver um dispositivo a ser associado a um paciente e são identificadas por <b>(**)</b>.',
            bodyPartText:
                'Para a parte do corpo onde um dispositivo será alocado, há duas opções: <b>wrist</b> (Pulso) e <b>necklace</b> (Colar).',
            feedback1Text: 'Após incluir um arquivo e clicar em',
            feedback2Text: 'Adicionar',
            feedback3Text: ', há dois casos distintos:',
            feedback4Text:
                'Em caso de sucesso, será feito o download de um arquivo csv com uma coluna nova chamada',
            feedback5Text: 'STATUS',
            feedback6Text: 'com valores',
            feedback7Text: 'SUCCESS',
            feedback8Text:
                'Em caso de falha, será feito o download de um arquivo csv com uma coluna nova chamada',
            feedback9Text: 'STATUS',
            feedback10Text: 'com valores',
            feedback11Text: 'FAIL',
            feedback12Text:
                'e novas colunas indicando o erro de cada coluna do arquivo original',
            requiredFieldsText:
                '<b style="color: darkred">*</b> Campos obrigatórios!',
            successText: 'Usuário adicionado com sucesso!',
            addText: 'Adicionar',
            cancelText: 'Cancelar',
            downloadText: 'Download',
            fileText: 'Arquivo',
            noSelectedFileText: 'Nenhum arquivo selecionado',
            chooseFileText: 'Escolher arquivo...',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: {
            downloadTemplateText:
                'Download the file with the format to add patients!',
            requiredFieldsInfoText:
                'Inside the file there are <b>required (*)</b> and not required columns to be filled. The not required columns can be left blank.',
            activeInfoText:
                'For active users, enter <b>1</b>. For inactive users, enter <b>0</b>.',
            dateFormatInfoText: 'For dates, use the format <b>yyyy-mm-dd</b>.',
            languageFormatText:
                'For the language of the email sent to the patient, there are three options: <b>en</b> (English), <b>pt</b> (Portuguese) and <b>ge</b> (German).',
            requiredFieldsWithConditionInfoText:
                'Inside the file there are columns related to the device that can be associated with a patient. They are mandatory columns only in the case of a device to be associated with a patient and are identified by <b>(**)</b>.',
            bodyPartText:
                'For the body part where a device will be associated, there are two options: <b>wrist</b> (Wrist) e <b>necklace</b> (Necklace).',
            feedback1Text: 'After adding a file and clicking',
            feedback2Text: 'Add',
            feedback3Text: ', there are two distinct cases:',
            feedback4Text:
                'In case of success, a csv file will be downloaded with a new column called',
            feedback5Text: 'STATUS',
            feedback6Text: 'with values',
            feedback7Text: 'SUCCESS',
            feedback8Text:
                'In case of failure, a csv file will be downloaded with a new column called',
            feedback9Text: 'STATUS',
            feedback10Text: 'with values',
            feedback11Text: 'FAIL',
            feedback12Text:
                'and new columns indicating the error of each column of the original file',
            requiredFieldsText:
                '<b style="color: darkred">*</b> Required fields!',
            successText: 'Patient successfully added!',
            addText: 'Add',
            cancelText: 'Cancel',
            downloadText: 'Download',
            fileText: 'File',
            noSelectedFileText: 'No files currently selected for upload',
            chooseFileText: 'Choose file...',
        },
        manager: {
            downloadTemplateText:
                'Download the file with the format to add patients!',
            requiredFieldsInfoText:
                'Inside the file there are <b>required (*)</b> and not required columns to be filled. The not required columns can be left blank.',
            activeInfoText:
                'For active users, enter <b>1</b>. For inactive users, enter <b>0</b>.',
            dateFormatInfoText: 'For dates, use the format <b>yyyy-mm-dd</b>.',
            languageFormatText:
                'For the language of the email sent to the patient, there are three options: <b>en</b> (English), <b>pt</b> (Portuguese) and <b>ge</b> (German).',
            requiredFieldsWithConditionInfoText:
                'Inside the file there are columns related to the device that can be associated with a patient. They are mandatory columns only in the case of a device to be associated with a patient and are identified by <b>(**)</b>.',
            bodyPartText:
                'For the body part where a device will be associated, there are two options: <b>wrist</b> (Wrist) e <b>necklace</b> (Necklace).',
            feedback1Text: 'After adding a file and clicking',
            feedback2Text: 'Add',
            feedback3Text: ', there are two distinct cases:',
            feedback4Text:
                'In case of success, a csv file will be downloaded with a new column called',
            feedback5Text: 'STATUS',
            feedback6Text: 'with values',
            feedback7Text: 'SUCCESS',
            feedback8Text:
                'In case of failure, a csv file will be downloaded with a new column called',
            feedback9Text: 'STATUS',
            feedback10Text: 'with values',
            feedback11Text: 'FAIL',
            feedback12Text:
                'and new columns indicating the error of each column of the original file',
            requiredFieldsText:
                '<b style="color: darkred">*</b> Required fields!',
            successText: 'User successfully added!',
            addText: 'Add',
            cancelText: 'Cancel',
            downloadText: 'Download',
            fileText: 'File',
            noSelectedFileText: 'No files currently selected for upload',
            chooseFileText: 'Choose file...',
        },
        condor_admin: null,
    },
};
