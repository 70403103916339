import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, CardFooter, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import FileInput from '../../../components/inputForm/inputFile';
import { setLoading } from '../../../store/actions/loadingActions';
import { validateForm } from '../../../helpers/formValidator';
import { importUser } from '../../../services/api/users';
import { fetchAddUserTemplateCsv } from '../../../services/api/files';
import history from '../../../services/history';

ImportUser.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function ImportUser({ currentUser }) {
    const formRef = useRef(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const texts = pageConfig[lang][currentUser.permissions];

    const { schema } = schemaConfig[lang][currentUser.permissions];

    async function handleSubmit(data) {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        formRef.current.setErrors(errors);

        const currentDate = new Date();

        const timezoneOffset = currentDate.getTimezoneOffset();

        if (isValid) {
            dispatch(setLoading(true));

            const response = await importUser({
                ...parsedData,
                timestamp: currentDate.getTime() - timezoneOffset * 60 * 1000,
            });

            dispatch(setLoading(false));

            if (response) {
                if ('message' in response) {
                    toast.warning(response.message, {
                        autoClose: 10000,
                    });
                } else {
                    toast.success(texts.successText);

                    history.push('/users');
                }
            }
        }
    }

    async function handleClick() {
        dispatch(setLoading(true));

        await fetchAddUserTemplateCsv();

        dispatch(setLoading(false));
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span data-cy="import_user_download_text">
                                {texts.downloadTemplateText}
                            </span>
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-12">
                            <ul>
                                <li
                                    data-cy="import_user_li1_text"
                                    dangerouslySetInnerHTML={{
                                        __html: texts.requiredFieldsInfoText,
                                    }}
                                ></li>
                                <li
                                    dangerouslySetInnerHTML={{
                                        __html: texts.activeInfoText,
                                    }}
                                    data-cy="import_user_li2_text"
                                ></li>
                                <li
                                    dangerouslySetInnerHTML={{
                                        __html: texts.dateFormatInfoText,
                                    }}
                                    data-cy="import_user_li3_text"
                                ></li>
                                <li
                                    dangerouslySetInnerHTML={{
                                        __html: texts.languageFormatText,
                                    }}
                                    data-cy="import_user_li4_text"
                                ></li>
                                <li
                                    data-cy="import_user_li5_text"
                                    dangerouslySetInnerHTML={{
                                        __html: texts.requiredFieldsWithConditionInfoText,
                                    }}
                                ></li>
                                <li
                                    data-cy="import_user_li6_text"
                                    dangerouslySetInnerHTML={{
                                        __html: texts.bodyPartText,
                                    }}
                                ></li>
                                <li data-cy="import_user_li7_text">
                                    {texts.feedback1Text}{' '}
                                    <b>{texts.feedback2Text}</b>
                                    {texts.feedback3Text}
                                    <ul>
                                        <li>
                                            {texts.feedback4Text}{' '}
                                            <b>{texts.feedback5Text}</b>{' '}
                                            {texts.feedback6Text}{' '}
                                            <b>{texts.feedback7Text}</b>.
                                        </li>
                                        <li>
                                            {texts.feedback8Text}{' '}
                                            <b>{texts.feedback9Text}</b>{' '}
                                            {texts.feedback10Text}{' '}
                                            <b>{texts.feedback11Text}</b>{' '}
                                            {texts.feedback12Text}.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-12">
                            <Button
                                type="button"
                                color="primary"
                                data-cy="import_user_download_button"
                                onClick={() => handleClick()}
                            >
                                {texts.downloadText}
                            </Button>
                        </FormGroup>
                    </FormGroup>

                    <hr />

                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-5">
                            <FileInput
                                name="file"
                                label={texts.fileText}
                                label2={texts.chooseFileText}
                                noselectedfiletext={texts.noSelectedFileText}
                                accept=".csv"
                                placeholder={texts.fileText}
                                data-cy="import_user_file_input"
                                required
                            />
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary"
                                className="mr-2"
                                type="submit"
                                data-cy="import_user_submit_button"
                            >
                                {texts.addText}
                            </Button>

                            <Link
                                to={history.location.state?.to || '/users'}
                                className="btn btn-secondary"
                                data-cy="import_user_cancel_link"
                            >
                                {texts.cancelText}
                            </Link>
                        </div>
                    </FormGroup>
                </Form>
            </CardBody>
            <CardFooter>
                <p
                    dangerouslySetInnerHTML={{
                        __html: texts.requiredFieldText,
                    }}
                ></p>
            </CardFooter>
        </Card>
    );
}
