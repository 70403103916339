import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { setLoading } from '../../../store/actions/loadingActions';
import history from '../../../services/history';
import { fetchCompanies } from '../../../services/api/companies';
import { fetchSubordinates } from '../../../services/api/users';
import { fetchDevice } from '../../../services/api/devices';
import { MyForm as Form } from './components/form';

EditDevice.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function EditDevice({ currentUser }) {
    const [device, setDevice] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [usersOptions, setUsersOptions] = useState([]);
    const [userFields, setUserFields] = useState([]);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];

    const schemaData = schemaConfig[lang][currentUser.permissions];

    function addField() {
        setUserFields((prevState) => {
            const newState = [...prevState];

            newState.push(crypto.randomUUID());

            return newState;
        });
    }

    function removeField(index) {
        setUserFields((prevState) => {
            const newState = [...prevState];

            newState.splice(index, 1);

            return newState;
        });
    }

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            if (history.location.state?.entity) {
                const deviceId = history.location.state.entity.id;

                const deviceData = await fetchDevice(deviceId);

                if (!deviceData?.device) {
                    history.push('/devices');
                    return;
                }

                const tempDevice = deviceData.device;

                if (currentUser.permissions === 'condor_admin') {
                    const data = await fetchCompanies();

                    setCompanies(data?.companies);
                } else {
                    const usersData = await fetchSubordinates();

                    setUsersOptions(
                        usersData?.users
                            ?.map((user) => ({
                                value: user.id,
                                label: user.name,
                            }))
                            .filter(
                                (field) => field.value !== tempDevice.user_id
                            )
                    );

                    setUserFields(
                        tempDevice.shared_users.map((_) => crypto.randomUUID())
                    );
                }

                setDevice(tempDevice);
            } else {
                history.push('/devices');
            }

            dispatch(setLoading(false));
        })();
    }, []);

    if (!device) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form
                    companies={companies}
                    usersOptions={usersOptions}
                    currentUser={currentUser}
                    device={device}
                    schema={schemaData.schema}
                    userFields={userFields}
                    addField={addField}
                    removeField={removeField}
                    texts={{
                        serialNumberText: pageData.serialNumberText,
                        brandText: pageData.brandText,
                        modelText: pageData.modelText,
                        actLumusText: pageData.actLumusText,
                        companyText: pageData.companyText,
                        purchaseDateText: pageData.purchaseDateText,
                        eraseDeviceMemoryText: pageData.eraseDeviceMemoryText,
                        connectionPeriodText: pageData.connectionPeriodText,
                        autosyncText: pageData.autosyncText,
                        yesText: pageData.yesText,
                        noText: pageData.noText,
                        successText: pageData.successText,
                        saveText: pageData.saveText,
                        cancelText: pageData.cancelText,
                        addText: pageData.addText,
                        removeText: pageData.removeText,
                        mainPatientText: pageData.mainPatientText,
                        patientText: pageData.patientText,
                    }}
                />
            </CardBody>
        </Card>
    );
}
