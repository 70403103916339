import { toast } from 'react-toastify';

import { store } from '../../store';
import { logout } from '../../store/actions/authActions';
import { hideLoading } from '../../store/actions/loadingActions';

export function successResponse(response) {
    if ('content-disposition' in response?.headers) {
        return response;
    } else if (response.data) {
        return response.data;
    } else {
        return response;
    }
}

export function failureResponse(error) {
    const { lang } = store.getState().lang;

    if (error.response) {
        switch (error.response.status) {
            case 401:
                toast.error(error.response.data.error);
                store.dispatch(hideLoading());
                store.dispatch(logout());
                break;
            default:
                toast.error(error.response.data.error);
                break;
        }
    } else if (error.request) {
        switch (lang) {
            case 'pt':
                toast.error('Problemas para se conectar ao servidor!');
                break;
            case 'en':
                toast.error('Trouble connecting to the server!');
                break;
        }
    } else {
        switch (lang) {
            case 'pt':
                toast.error('Algum erro inesperado ocorreu!');
                break;
            case 'en':
                toast.error('Some unexpected error has occurred!');
                break;
        }
    }
    store.dispatch(hideLoading());

    return Promise.reject(error);
}
