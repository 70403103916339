export default {
    pt: {
        customer_admin: {
            searchByNameText: 'Procurar paciente por nome',
            searchBySecondaryIdText: 'Procurar paciente por id secundário',
            addText: 'Adicionar',
            addUserText: 'Adicionar paciente',
            idText: 'ID',
            nameText: 'Nome',
            secondaryIdText: 'ID Secundário',
            emailText: 'e-Mail',
            activeText: 'Ativo',
            actionsText: 'Ações',
            reportText: 'Relatório',
            editText: 'Editar',
            credentialsText: 'Credenciais',
            deleteText: 'Excluir',
            yesText: 'Sim',
            noText: 'Não',
            noUsersText: 'Você não possui pacientes cadastrados!',
            importUserText: 'Importar pacientes',
            exportTableText: 'Exportar tabela',
        },
        manager: {
            searchByNameText: 'Procurar paciente por nome',
            searchBySecondaryIdText: 'Procurar paciente por id secundário',
            addText: 'Adicionar',
            addUserText: 'Adicionar paciente',
            idText: 'ID',
            nameText: 'Nome',
            secondaryIdText: 'ID Secundário',
            emailText: 'e-Mail',
            activeText: 'Ativo',
            actionsText: 'Ações',
            reportText: 'Relatório',
            editText: 'Editar',
            credentialsText: 'Credenciais',
            deleteText: 'Excluir',
            yesText: 'Sim',
            noText: 'Não',
            noUsersText: 'Você não possui pacientes cadastrados!',
            importUserText: 'Importar pacientes',
            exportTableText: 'Exportar tabela',
        },
        condor_admin: {
            searchByNameText: 'Procurar usuário por nome',
            searchBySecondaryIdText: 'Procurar usuário por id secundário',
            addText: 'Adicionar',
            addUserText: 'Adicionar usuário',
            idText: 'ID',
            companyNameText: 'Empresa',
            nameText: 'Nome',
            secondaryIdText: 'ID Secundário',
            emailText: 'e-Mail',
            activeText: 'Ativo',
            userTypeText: 'Tipo de Usuário',
            actionsText: 'Ações',
            editText: 'Editar',
            credentialsText: 'Credenciais',
            deleteText: 'Excluir',
            yesText: 'Sim',
            noText: 'Não',
            patientText: 'Paciente',
            managerText: 'Gestor',
            condorAdminText: 'Condor Admin',
            managerAdminText: 'Gestor Admin',
            noUsersText: 'Você não possui clientes cadastrados!',
            exportTableText: 'Exportar tabela',
        },
    },
    en: {
        customer_admin: {
            searchByNameText: 'Search patient by name',
            searchBySecondaryIdText: 'Search patient by secondary ID',
            addText: 'Add',
            addUserText: 'Add patient',
            idText: 'ID',
            nameText: 'Name',
            secondaryIdText: 'Secondary ID',
            emailText: 'e-Mail',
            activeText: 'Active',
            actionsText: 'Actions',
            reportText: 'Report',
            editText: 'Edit',
            credentialsText: 'Credentials',
            deleteText: 'Delete',
            yesText: 'Yes',
            noText: 'No',
            noUsersText: `You dont't have registered patients!`,
            importUserText: 'Import patients',
            exportTableText: 'Export table',
        },
        manager: {
            searchByNameText: 'Search patient by name',
            searchBySecondaryIdText: 'Search patient by secondary ID',
            addText: 'Add',
            addUserText: 'Add patient',
            idText: 'ID',
            nameText: 'Name',
            secondaryIdText: 'Secondary ID',
            emailText: 'e-Mail',
            activeText: 'Active',
            actionsText: 'Actions',
            reportText: 'Report',
            editText: 'Edit',
            credentialsText: 'Credentials',
            deleteText: 'Delete',
            yesText: 'Yes',
            noText: 'No',
            noUsersText: `You dont't have registered patients!`,
            importUserText: 'Import patients',
            exportTableText: 'Export table',
        },
        condor_admin: {
            searchByNameText: 'Search user by name',
            searchBySecondaryIdText: 'Search user by secondary ID',
            addText: 'Add',
            addUserText: 'Add user',
            idText: 'ID',
            companyNameText: 'Company',
            nameText: 'Name',
            secondaryIdText: 'Secondary ID',
            emailText: 'e-Mail',
            activeText: 'Active',
            userTypeText: 'User Type',
            actionsText: 'Actions',
            editText: 'Edit',
            credentialsText: 'Credentials',
            deleteText: 'Delete',
            yesText: 'Yes',
            noText: 'No',
            patientText: 'Patient',
            managerText: 'Manager',
            condorAdminText: 'Condor Admin',
            managerAdminText: 'Manager Admin',
            noUsersText: `You dont't have registered customers!`,
            exportTableText: 'Export table',
        },
    },
};
