import Input from '../../../components/inputForm/inputText';
import Radio from '../../../components/inputForm/inputRadio';

export default {
    pt: {
        sleepDiaryText: 'Diário de Sono',
        readyToStartText: 'Pronto para começar?',
        fillOutTheFormText:
            'Preencha o formulário e comece a adicionar pacientes!',
        signUpText: 'Assine agora o plano',
        payOnlyText: 'e pague apenas',
        byMonthText: '/mês durante',
        monthsText: 'meses',
        requiredFieldsText: 'Campos obrigatórios',
        planValuesAfterDiscountText: 'Valores mensais do plano após',
        noLoyaltyText: 'sem fidelidade',
        sixMonthLoyaltyText: 'com fidelidade de 6 meses',
        oneYearLoyaltyText: 'com fidelidade de 1 ano',
        signUpText2: 'Assine agora o plano Standard e pague apenas',
        twoMonthText: '/mês durante 2 meses!',
        threeMonthText: '/mês durante 3 meses!',
        pageTitle: 'Diário de Sono',
        pageSubtitle: 'Pronto para começar?',
        pageSubtitle2: 'Preencha o formulário e comece a adicionar pacientes!',
        twoMonthDiscountInformation:
            'Assine agora o plano Standard e pague apenas R$1,99/mês durante 2 meses!',
        threeMonthDiscountInformation:
            'Assine agora o plano Standard e pague apenas R$1,99/mês durante 3 meses!',
        formLabel: 'Crie sua conta do Diário de Sono',
        creditCardLabelText: 'Dados do cartão <b style="color: darkred">*</b>',
        fields: {
            companyInfo: [
                {
                    component: Input,
                    props: {
                        name: 'company_name',
                        type: 'text',
                        label: 'Nome da empresa <b style="color: darkred">*</b>',
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Seu nome <b style="color: darkred">*</b>',
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        label: 'E-mail <b style="color: darkred">*</b>',
                        type: 'text',
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Radio,
                    props: {
                        name: 'share',
                        label: 'Gostaria de compartilhar pacientes com outros<br/> gestores da empresa? <b style="color: darkred">*</b>',
                        options: [
                            { id: 'yes', value: true, label: 'Sim' },
                            { id: 'no', value: false, label: 'Não' },
                        ],
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'password',
                        label: 'Senha <b style="color: darkred">*</b>',
                        type: 'password',
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'confirm_password',
                        label: 'Confirmação de senha <b style="color: darkred">*</b>',
                        type: 'password',
                    },
                    className: 'col-xs-12 col-md-12',
                },
            ],
            planInfo: [
                {
                    component: Input,
                    props: {
                        name: 'plan',
                        type: 'text',
                        label: 'Plano',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_patients',
                        type: 'text',
                        label: 'Número de pacientes',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'price',
                        type: 'text',
                        label: 'Preço',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
            ],
            new_card_info: {
                number: {
                    component: Input,
                    props: {
                        name: 'number',
                        type: 'text',
                        'data-iugu': 'number',
                        placeholder: 'Número do cartão',
                        className: 'credit_card_number',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                verification_value: {
                    component: Input,
                    props: {
                        name: 'verification_value',
                        type: 'text',
                        'data-iugu': 'verification_value',
                        placeholder: 'CVV',
                        className: 'credit_card_cvv',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                full_name: {
                    component: Input,
                    props: {
                        name: 'full_name',
                        type: 'text',
                        'data-iugu': 'full_name',
                        placeholder: 'Titular do cartão',
                        className: 'credit_card_name',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                expiration: {
                    component: Input,
                    props: {
                        name: 'expiration',
                        type: 'text',
                        'data-iugu': 'expiration',
                        placeholder: 'MM/AA',
                        className: 'credit_card_expiration',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            },
        },
        imgAltTexts: {
            cardTypes: 'Visa, Master, Diners. Amex',
            paymentByIugu: 'Pagamentos por Iugu',
        },
        createAccount: 'Criar conta',
        creating: 'Aguarde...',
        accessIt: ' Acesse',
        requiredFields: '<b style="color: darkred">*</b> Campos obrigatórios!',
        price2MonthDiscount: `<b style="color: darkred">**</b> Valores mensais do plano após 2 meses:
            <ul>
                <li>R$ 249,99 sem fidelidade.</li>
                <li>R$ 149,99 com fidelidade de 6 meses.</li>
                <li>R$ 129,99 com fidelidade de 1 ano.</li>
            </ul>`,
        price3MonthDiscount: `<b style="color: darkred">**</b> Valores mensais do plano após 3 meses:
            <ul>
                <li>R$ 249,99 sem fidelidade.</li>
                <li>R$ 149,99 com fidelidade de 6 meses.</li>
                <li>R$ 129,99 com fidelidade de 1 ano.</li>
            </ul>`,
        pageFooter: '© 2024 - SleepDiary',
        successTrialRegister:
            'Conta criada com sucesso! Verifique o e-mail cadastrado para obter as informações de login.',
        successRegister:
            'Estamos processando o seu pagamento! Assim que ele for confirmado, enviaremos informações de login no e-mail cadastrado.',
        paymentFailed: 'Não foi possível processar o pagamento!',
        failedRegister:
            'Ocorreu um erro ao processar seu pagamento! Verifique os dados do cartão e tente novamente.',
        iuguErrorsText: {
            numberErrorText: 'O número do cartão é inválido.',
            cvvErrorText: 'O código de segurança do cartão está incorreto.',
            fullNameErrorText: 'O nome do titular do cartão é inválido.',
            expirationErrorText: 'A data de validade é inválida.',
            defaultErrorText: 'Erro no cartão.',
        },
        stripeErrors: {
            expired_card: 'O cartão expirou.',
            incorrect_cvc: 'O código de segurança do cartão está incorreto.',
            processing_error:
                'Ocorreu um erro ao processar o cartão. Tente novamente em instantes.',
            incorrect_number: 'O número do cartão é inválido.',
            card_declined: 'O cartão foi recusado.',
            card_decline_codes: {
                approve_with_id: 'Não é possível autorizar o pagamento.',
                call_issuer:
                    'O cartão foi recusado por um motivo desconhecido.',
                card_not_supported: 'O cartão não aceita este tipo de compra.',
                card_velocity_exceeded:
                    'O cliente excedeu o saldo ou o limite de crédito disponível no cartão.',
                currency_not_supported:
                    'O cartão não aceita a moeda especificada.',
                do_not_honor:
                    'O cartão foi recusado por um motivo desconhecido.',
                duplicate_transaction:
                    'Uma transação com valor e dados de cartão de crédito idênticos foi executada recentemente.',
                expired_card: 'O cartão expirou.',
                insufficient_funds:
                    'O cartão não tem saldo suficiente para concluir a compra.',
                invalid_amount:
                    'O valor do pagamento é inválido ou excede o valor permitido.',
                invalid_cvc: 'O código de segurança do cartão está incorreto.',
                invalid_expiry_month: 'O mês de validade é inválido.',
                invalid_expiry_year: 'O ano de validade é inválido.',
                invalid_number: 'O número do cartão é inválido.',
                not_permitted: 'O pagamento não é permitido.',
                processing_error:
                    'Ocorreu um erro ao processar o cartão. Tente novamente em instantes.',
            },
        },
    },
    en: {
        sleepDiaryText: 'Sleep Diary',
        readyToStartText: 'Ready to start?',
        fillOutTheFormText: 'Fill out the form and start adding patients!',
        signUpText: 'Sign up for the',
        payOnlyText: 'plan now and pay only',
        byMonthText: '/month for',
        monthsText: 'months',
        requiredFieldsText: 'Required fields',
        planValuesAfterDiscountText: 'Monthly plan values after',
        noLoyaltyText: 'no loyalty',
        sixMonthLoyaltyText: 'with 6 month loyalty',
        oneYearLoyaltyText: 'with 1 year loyalty',
        signUpText2: 'Sign up for the Standard plan now and pay only',
        twoMonthText: '/month for 2 months!',
        threeMonthText: '/month for 3 months!',
        pageTitle: 'Sleep Diary',
        pageSubtitle: 'Ready to start?',
        pageSubtitle2: 'Fill out the form and start adding patients',
        twoMonthDiscountInformation:
            'Sign up for the Standard plan now and pay only $1.99/month for 2 months!',
        threeMonthDiscountInformation:
            'Sign up for the Standard plan now and pay only $1.99/month for 3 months!',
        formLabel: 'Create your Sleep Diary account',
        creditCardLabelText: 'Credit card data <b style="color: darkred">*</b>',
        fields: {
            companyInfo: [
                {
                    component: Input,
                    props: {
                        name: 'company_name',
                        type: 'text',
                        label: 'Company name <b style="color: darkred">*</b>',
                        required: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Your name <b style="color: darkred">*</b>',
                        required: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        label: 'E-mail <b style="color: darkred">*</b>',
                        type: 'text',
                        required: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Radio,
                    props: {
                        name: 'share',
                        label: 'Would you like to share patients with other<br/> company managers? <b style="color: darkred">*</b>',
                        options: [
                            { id: 'yes', value: true, label: 'Yes' },
                            { id: 'no', value: false, label: 'No' },
                        ],
                        required: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'password',
                        label: 'Password <b style="color: darkred">*</b>',
                        type: 'password',
                        required: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'confirm_password',
                        label: 'Password confirmation <b style="color: darkred">*</b>',
                        type: 'password',
                        required: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
            ],
            planInfo: [
                {
                    component: Input,
                    props: {
                        name: 'plan',
                        type: 'text',
                        label: 'Plan',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_patients',
                        type: 'text',
                        label: 'Number of patients',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'price',
                        type: 'text',
                        label: 'Price',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
            ],
            new_card_info: {
                number: {
                    component: Input,
                    props: {
                        name: 'number',
                        type: 'text',
                        'data-iugu': 'number',
                        placeholder: 'Card number',
                        className: 'credit_card_number',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                verification_value: {
                    component: Input,
                    props: {
                        name: 'verification_value',
                        type: 'text',
                        'data-iugu': 'verification_value',
                        placeholder: 'CVV',
                        className: 'credit_card_cvv',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                full_name: {
                    component: Input,
                    props: {
                        name: 'full_name',
                        type: 'text',
                        'data-iugu': 'full_name',
                        placeholder: 'Cardholder',
                        className: 'credit_card_name',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                expiration: {
                    component: Input,
                    props: {
                        name: 'expiration',
                        type: 'text',
                        'data-iugu': 'expiration',
                        placeholder: 'MM/YY',
                        className: 'credit_card_expiration',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            },
        },
        imgAltTexts: {
            cardTypes: 'Visa, Master, Diners. Amex',
            paymentByIugu: 'Payments by Iugu',
        },
        createAccount: 'Create account',
        creating: 'Loading...',
        accessIt: ' Access',
        requiredFields: '<b style="color: darkred">*</b> Required fields!',
        price2MonthDiscount: `<b style="color: darkred">**</b> Monthly plan values after 2 months:
            <ul>
                <li>$124.99 no loyalty.</li>
                <li>$74.99 with 6 month loyalty.</li>
                <li>$64.99 with 1 year loyalty.</li>
            </ul>`,
        price3MonthDiscount: `<b style="color: darkred">**</b> Monthly plan values after 3 months:
            <ul>
                <li>$124.99 no loyalty.</li>
                <li>$74.99 with 6 month loyalty.</li>
                <li>$64.99 with 1 year loyalty.</li>
            </ul>`,
        pageFooter: '© 2024 - SleepDiary',
        successTrialRegister:
            'Account succesfully registered! Check the registered email for login information.',
        successRegister:
            'We are processing your payment! As soons as it is confirmed, we will send you login information in the registered e-mail.',
        paymentFailed: 'It was not possible to process your payment!',
        failedRegister:
            'There was an error processing your payment! Please check the card details and try again.',
        iuguErrorsText: {
            numberErrorText: 'Card number is invalid!',
            cvvErrorText: 'Security code is incorrect!',
            fullNameErrorText: 'Cardholder is incorrect!',
            expirationErrorText: 'Invalid expiry date!',
            defaultErrorText: 'Card error!',
        },
        stripeErrors: {
            expired_card: 'The card has expired.',
            incorrect_cvc: 'The card verification code is incorrect.',
            processing_error:
                'An error occurred while processing the card. Please try again in a moment.',
            incorrect_number: 'The card number is invalid.',
            card_declined: 'The card has been declined.',
            card_decline_codes: {
                approve_with_id: 'Cannot approve payment.',
                call_issuer: 'The card was declined for an unknown reason.',
                card_not_supported:
                    'The card does not accept this type of purchase.',
                card_velocity_exceeded:
                    'The customer has exceeded the balance or credit limit available on the card.',
                currency_not_supported:
                    'The card does not accept the specified currency.',
                do_not_honor: 'The card was declined for an unknown reason.',
                duplicate_transaction:
                    'A transaction with identical amount and credit card data was performed recently.',
                expired_card: 'The card has expired.',
                insufficient_funds:
                    'Card does not have sufficient balance to complete purchase.',
                invalid_amount:
                    'The payment amount is invalid or exceeds the allowed amount.',
                invalid_cvc: 'The card verification code is incorrect.',
                invalid_expiry_month: 'The expiration month is invalid.',
                invalid_expiry_year: 'The expiration year is invalid.',
                invalid_number: 'The card number is invalid.',
                not_permitted: 'Payment is not allowed.',
                processing_error:
                    'An error occurred while processing the card. Please try again in a moment.',
            },
        },
    },
};
